@font-face {
  font-family: LatoThin;
  src: url("/assets/fonts/Lato-Thin.ttf");
}
@font-face {
  font-family: LatoLight;
  src: url("/assets/fonts/Lato-Light.ttf");
}
@font-face {
  font-family: LatoRegular;
  src: url("/assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: LatoBold;
  src: url("/assets/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: LatoBlack;
  src: url("/assets/fonts/Lato-Black.ttf");
}

* {
  font-family: "LatoRegular";
}
