.item-input {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .img-ico {
    position: absolute;
    bottom: 20px;
    left: 10px;
    z-index: 10;
    width: 40px;
    height: 40px;

    @media (max-width: 375px) {
      width: 25px;
      height: 25px;
      bottom: 12px;
    }
  }

  ion-label {
    font-family: var(--ion-font-regular);
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    margin-left: 10px;

    @media (max-width: 375px) {
      font-size: 16px;
      line-height: 36px;
    }
  }
  .input-default {
    background: var(--ion-color-white);
    min-height: 80px;
    border-radius: 16px;
    font-family: var(--ion-font-regular);
    font-size: 22px;
    --padding-start: 60px;

    @media (max-width: 375px) {
      border-radius: 10px;
      font-size: 16px;
      min-height: 50px;
      --padding-start: 40px;
    }
  }
}

