/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/fonts";
@import "./theme/form";
@import "./theme/buttons";

ion-header {
  background: var(--color-background-default);
  border: 0 !important;
  box-shadow: unset !important;
  ion-toolbar {
    --background: transparent;
    --border-width: 0 !important;
  }
}

ion-content {
  --background: var(--color-background-default);
}

@media only screen and (min-width: 400px) {
  .modal-filter {
    justify-content: flex-end !important;
    .modal-wrapper {
      --max-width: 40% !important;
      --min-width: 40% !important;
      --min-height: 40% !important;
      --max-height: 40% !important;

      // @media (max-width: 580px), (orientation: portrait) {
      //   --max-width: 80%;
      //   --min-width: 100%;
      // }
    }
  }
}
.modal-default{
  --min-width: 100% !important;
  --min-height: 100% !important;
}

.modal-default.modal-filter{
  @media only screen and (min-width: 1000px) {
    --min-width: 40% !important;
    --min-height: 100% !important;
  }
}

.modal-filter {
  justify-content: flex-end !important;
  .modal-wrapper {
    --min-width: 60% !important;
    --min-height: 100% !important;

    // @media (max-width: 580px), (orientation: portrait) {
    //   --max-width: 80%;
    //   --min-width: 100%;
    // }
  }

  ion-backdrop {
    opacity: 0.7 !important;
    background: rgba(38, 58, 71, 1) !important;
  }
}

.popover-date {
  --width: 100%;
  --max-width: 350px;
  --min-width: 250px;
}
