.btn-orange {
  --border-radius: 10px;
  --background: var(--ion-color-orange);
  --box-shadow: 0;
  width: 350px;
  min-height: 70px;

  @media (max-width: 375px) {
    min-height: 50px;
  }

  ion-label {
    font-family: var(--ion-font-bold);
    font-size: 20px;
    color: var(--ion-color-white);
    text-transform: none;
    @media (max-width: 375px) {
      font-size: 16px;
    }
  }
}

.btn-white {
  --border-radius: 10px;
  --background: var(--ion-color-white);
  --box-shadow: 0;
  width: 350px;
  min-height: 70px;
  border: 1px solid var(--ion-color-orange);
  border-radius: 10px;

  @media (max-width: 375px) {
    min-height: 50px;
  }

  ion-label {
    font-family: var(--ion-font-bold);
    font-size: 20px;
    color: var(--ion-color-orange);
    text-transform: none;
    @media (max-width: 375px) {
      font-size: 16px;
    }

  }

  ion-icon  {
    color: var(--ion-color-orange);
  }
}
